// COMPONENTS
import React from 'react'
import MarkdownBody from 'elements/MarkdownBody'
import Section from 'layout/Section'
import SignUpForm from './form.js'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
// ICONS
import Icon from 'elements/Icon'

// Signup component (as section)
const SignUp = ({ content }) => {
  const { section, section: {inverted}, style, size='md', title, subtitle, button, icon, list, bodyMd, footer } = content

  return (
  <Section content={section}>
    <Container className='rounded' style={style} fluid>
      <Row>
        <Col className='text-center pb-2' xs={12}>
          {title && <h1 className='pt-5'>{title}</h1>}
          {icon && <Icon name={icon} />}
          {subtitle && <h4>{subtitle}</h4>}
        </Col>
        <Col className='text-center pb-2' xs={12}>
          <MarkdownBody
            addClass='text-center'
            markdown={bodyMd}
            inverted={inverted}
          />
        </Col>
      </Row>
      <Row>
        <Col className='text-center pb-2' lg={{span: 8, offset: 2}} md={{span: 10, offset: 1}} xs={12}>
          <SignUpForm list={list} button={button} footer={footer} size={size} />
        </Col>
      </Row>
    </Container>
  </Section>
  )
}

export default SignUp
