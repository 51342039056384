// COMPONENTS
import React, { useContext } from 'react'
import { ThemeContext } from 'layout/ThemeProvider'
import ContentContainer from 'layout/ContentContainer'
import SignUpForm from './form.js'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Simple one-liner signup
const SimpleSignUp = ({ content }) => {
  const { themeMode } = useContext(ThemeContext)
  const { section: {inverted}, size='sm', title, button, list, footer } = content

  return(
  <ContentContainer id='simple-signup' type='simpleSignup' inverted={inverted}>
    <Row className='d-flex text-center'>
      <Col md={8} sm={12}>
        { title && <h5 className={`py-2 my-0 text-${themeMode}`} >{title}</h5> }
      </Col>
      <Col className='d-flex align-items-center text-center' md={4} sm={12}>
        <SignUpForm list={list} button={button} footer={footer} size={size} />
        {/* <p className='pl-1 m-0 text-small'>&#x2715;</p> */}
      </Col>
    </Row>
  </ContentContainer>
  )
}

export default SimpleSignUp