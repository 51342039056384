// COMPONENTS
import React, { useState } from 'react'
// import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
// LOGIC
// import { handleEnterKey } from 'apis/Browser'
import { signUp } from 'apis/EmailSignup'

// Modular form component for signup - handles signup logic
const SignUpForm = ({ list, button, footer, size='lg'}) => {
  const [email, setEmail] = useState(null)
  const [valid, setValid] = useState(false)
  const doSignUp = () => signUp(valid, email, list)
  return (
  <Form className='w-100'>
    <Form.Row>
      {/* <Col className='text-center' xs={12} md={size == 'lg' ? { span: 8, offset: 2 }:''}> */}
      <Col className='text-center' xs={12}>
        <InputGroup size={size}>
          <Form.Control
            required
            type='email'
            placeholder='Enter your email'
            // onKeyPress={e => handleEnterKey(e, doSignUp)}    // Disabled enter key to submit, causes bug on signup
            onChange={e => {
              setEmail(e.target.value)
              setValid(e.target.validity.valid)
            }}
          />
          <Form.Control.Feedback>OK!</Form.Control.Feedback>
          <InputGroup.Append>
            <Button variant={button.color} onClick={doSignUp}>
              {button.text}
            </Button>
          </InputGroup.Append>
        </InputGroup>
        {footer && <Form.Text className='text-muted'>{footer}</Form.Text>}
      </Col>
    </Form.Row>
  </Form>
  )
}

export default SignUpForm