module.exports = {
  // const defaultExports = {
  siteMetadata: {
    title: `neoevolutions`,
    description: `NeoEvolutions Blog`,
    author: `@neo`,
    email: `neosearhole@gmail.com`,
    twitter: `neooeevo`,
    logo: `https://neoevolutions.s3-us-west-2.amazonaws.com/blog/marl-sunglasses.png`,
    // GSDB API
    gsdb: {
      docUrl: `https://docs.google.com/spreadsheets/d/1egVbmD2-5nqVtkFfmahRlF_32m1jBfdMQREfv9yrSLk/edit#gid=0`,
    },
  },
  plugins: [
    {
      // Google Analytics plugin
      resolve: `gatsby-plugin-gtag`,
      options: {
        trackingId: `UA-127560706-1`, // your google analytics tracking id
        head: false, // Puts tracking script in the head instead of the body
        anonymize: true, // enable ip anonymization
      },
    },

    {
      // Root import plugin
      resolve: `gatsby-plugin-root-import`,
      options: {
        root: `${__dirname}/`,
        content: `${__dirname}/content`,
        src: `${__dirname}/src`,
        // apis: `${__dirname}/src/apis`,
        apis: `${__dirname}/../apis`,
        // components: `${__dirname}/src/components`,
        components: `${__dirname}/../react-components/neo`,
        BlogPost: `${__dirname}/../react-components/neo/BlogPost`,
        layout: `${__dirname}/../react-components/neo/layout`,
        elements: `${__dirname}/../react-components/neo/elements`,
        templates: `${__dirname}/../react-components/neo/templates`,
        customTemplates: `${__dirname}/src/templates`,
        LandingPage: `${__dirname}/../react-components/neo/LandingPage`,
        hooks: `${__dirname}/src/hooks`,
        pages: `${__dirname}/src/pages`,
        styles: `${__dirname}/src/styles`,
        designImages: `${__dirname}/static/images/design`,
      },
    },

    {
      // Page sources
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `pages`,
        path: `${__dirname}/src/pages/`,
      },
    },
    {
      // Content sources
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `markdown-pages`,
        path: `${__dirname}/content`,
      },
    },

    {
      // Image sources
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/static/images`,
      },
    },

    {
      // Internal links plugin
      resolve: `gatsby-plugin-catch-links`,
      options: {
        excludePattern: /(excluded-link|external)/,
      },
    },

    {
      // Importing SVG images as components
      resolve: `gatsby-plugin-react-svg`,
      options: {
        rule: {
          include: /images/,
        },
      },
    },

    `gatsby-plugin-sass`, // Needs to be loaded before cms and fonts

    {
      // Web font loader plugin
      resolve: `gatsby-plugin-web-font-loader`,
      options: {
        google: {
          families: [`Raleway` /*, `Quicksand`, `Droid Sans`, `Droid Serif`*/],
        },
      },
    },

    {
      // Netlify CMS plugin
      resolve: `gatsby-plugin-netlify-cms`,
      options: {
        modulePath: `${__dirname}/src/cms/cms.js`,
        // stylesPath: `${__dirname}/src/styles/bootstrap.min.css`
      },
    },

    {
      // Web app manifest plugin
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#e83e8c`,
        theme_color: `#e83e8c`,
        display: `minimal-ui`,
        // This path is relative to the root of the site.
        // "Automatic" mode seems to work best here, using the largest icon
        icon: `static/images/branding/android-chrome-512x512.png`,
      },
    },
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-twitter`,
    // `gatsby-transformer-remark`,
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        plugins: [
          {
            resolve: `gatsby-remark-prismjs`,
            options: {
              classPrefix: 'language-',
              inlineCodeMarker: null,
              aliases: {},
              showLineNumbers: false,
              noInlineHighlight: false,
              languageExtensions: [
                {
                  language: 'superscript',
                  extend: 'javascript',
                  definition: {
                    superscript_types: /(SuperType)/,
                  },
                  insertBefore: {
                    function: {
                      superscript_keywords: /(superif|superelse)/,
                    },
                  },
                },
              ],
              prompt: {
                user: 'neo',
                host: 'localhost',
                global: false,
              },
              escapeEntities: {},
            },
          },
          {
            resolve: `gatsby-remark-embedder`,
            options: {
              customTransformers: [
                // Your custom transformers
              ],
              services: {
                // The service-specific options by the name of the service
              },
            },
          },
        ],
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,

    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}

// const util = require('util')
// // alternative shortcut
// console.log(util.inspect(module.exports, false, null, true))
